<template>
  <div class="opinions">
    <h1>Kundenmeinungen</h1>
    <div class="customers">
      <transition name="slide-fade">

        <div v-for="(m,index) in block.meinung" :key="index" v-if="start===index">
          <div v-html="m.text"></div>
          <br>
          <div v-html="m.name"></div>
          <div class="paranthese">&#10078;</div>
        </div>
      </transition>
      <transition name="slide-fade">
        <div v-for="(m,index) in block.meinung" :key="index" v-if="start+1===index">
          <div v-html="m.text"></div>
          <br>
          <div v-html="m.name"></div>
          <div class="paranthese">&#10078;</div>
        </div>
      </transition>
    </div>
    <div style="" v-if="block.meinung.length>2" class="pages">

      <div @click="start=index-1" class="arrow" v-for="(o, index) in block.meinung" v-if="index % 2 ===1"
           style="margin: 5px 10px;" :class="{active:start===index-1}">
        {{ Math.ceil(index / 2) }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Opinions.vue",
  data() {
    return {
      start: 0
    }
  },
  methods: {
    forward() {
      if (this.block.meinung.length > this.start + 2) {
        this.start = this.start + 2;
      } else this.start = 0
    }
  },
  props: ['block']
}
</script>

<style scoped lang="scss">
.opinions {
  padding: 20px 5% 50px;
  display: flex;
  flex-direction: column;

  h1 {
    text-align: left;
  }

  .slide-fade-enter-active {
    transition: all .6s ease;
  }

  .slide-fade-leave-active {
    transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    display: none;
  }

  .slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */
  {
    transform: translateX(10%);
    opacity: 0;
  }

  background-color: $hellblau;

  .customers {
    display: flex;
    flex-wrap: wrap;
    min-height: 200px;
    @include mobile {
      width: 100%;
      padding-top: 50px;
      order: 3;
    }

    & > div {
      position: relative;
      width: calc(50% - 60px);
      margin-right: 20px;

      padding: 30px 20px 30px 20px;
      @include mobile {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
      }

      text-align: left;
      border-top: 2px #fff solid;
      border-bottom: 2px #fff solid;

      .paranthese {
        font-size: 40px;
        color: $lila;
        position: absolute;
        left: calc(50% - 30px);
        top: -20px;
        background-color: $hellblau;
        padding: 0 20px;
      }
    }
  }

  .arrow {
    font-size: 30px;
    color: $lila;

    &.active {

      font-weight: 900;
    }

    cursor: pointer;
  }

  .pages {
    display: flex;
    justify-content: center;
    @include mobile {
      order: 2;
    }
  }
}
</style>