<template>
  <div class="footer">
    <hr>

    <span v-for="(m,index) in footermenu" :key="m.id">
      <span v-if="index>0"> | </span>
     <router-link :to="shortLink(m.url)!='/'?shortLink(m.url):'/sortiment'"
                  v-html="m.title"></router-link>
    </span>
  </div>
</template>

<script>
import store from '@/store'

export default {
  created() {
    store.dispatch('getFootermenu')
  },
  name: "Footer",
  computed: {
    footermenu: () => store.state.footermenu,
    isFixed: () => store.state.fixed
  },

}
</script>

<style scoped lang="scss">
.footer {
  background-color: #fff;
  width: 100%;
  padding-bottom: 20px;
}

a {
  color: #444;
  text-decoration: none;
  //text-transform: uppercase;
  font-weight: 400;
  @include phone {
    font-size: 14px;
    margin: 10px 0;
  }

  &.router-link-exact-active {


    color: $gruen;
    font-weight: 700;

  }
}
</style>