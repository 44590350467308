<template>
  <div class="gallery">
<div class="bild" v-for="g in gallery.bild">
<!--  {{g.url}}-->
  <img :src="g.sizes.medium_large" alt="">
</div>
<!--    {{gallery}}-->

    <!--  {{block}}-->
  </div>
</template>

<script>
export default {
  name: "Gallery",
  props: ['gallery'],
  methods: {
    hexToRGB(hex, alpha) {
      var r = parseInt(hex.slice(1, 3), 16),
          g = parseInt(hex.slice(3, 5), 16),
          b = parseInt(hex.slice(5, 7), 16);

      if (alpha) {
        return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
      } else {
        return "rgb(" + r + ", " + g + ", " + b + ")";
      }
    }

  }
}
</script>

<style scoped lang="scss">
.gallery {

  padding-top: 40px;
  padding-bottom: 40px;

background-color: rgba($hellblau,0.5);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: calc(100vw);
  .bild {
   height: min(512px, 100vw);

    margin: 5px;

    img {
      max-height: 100%;
      max-width: 100%;
    }

  }
}
</style>