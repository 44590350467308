<template>
  <div :class="page.acf.farbe" class="pagecontent">

    <Slider :slides="page.acf.feld" v-if="page.acf.feld"></Slider>
    <div style="display: flex;justify-content: center">
      <div class="content" v-if="page.content.rendered">

        <h1 v-html="page.title.rendered" v-if="page.title.rendered"></h1>
        <div v-html="page.content.rendered" v-if="page.content.rendered">
        </div>

      </div>
    </div>
    <div v-for="block in page.acf.blocks">
      <Widetext :block="block" v-if="block.acf_fc_layout==='widetext'"></Widetext>

      <Lilabox :block="block" v-if="block.acf_fc_layout==='textbox_lila'"></Lilabox>
      <Opinions :block="block" v-if="block.acf_fc_layout==='opinions'"></Opinions>
      <Gallery :gallery="block" v-if="block.acf_fc_layout==='galerie'"></Gallery>
      <SimpleGallery :gallery="block" v-if="block.acf_fc_layout==='einfache_galerie'"></SimpleGallery>

      <Bubbles :block="block" v-if="block.acf_fc_layout==='bubbles'"></Bubbles>
      <Iconbar :block="block" v-if="block.acf_fc_layout==='iconbar'"></Iconbar>
<Jobwidget v-if="block.acf_fc_layout==='jobwidget'"></Jobwidget>

      <!--      <Social></Social>-->
    </div>

  </div>
</template>

<script>
import Slider from '@/components/parts/Slider.vue'
import Widetext from '@/components/parts/Widetext.vue'
import Lilabox from '@/components/parts/Lilabox.vue'
import Opinions from '@/components/parts/Opinions.vue'
import Gallery from '@/components/parts/Gallery.vue'
import SimpleGallery from '@/components/parts/SimpleGallery.vue'
import Bubbles from '@/components/parts/Bubbles.vue'
import Iconbar from '@/components/parts/Iconbar.vue'
import Social from '@/components/parts/Social.vue'
import Footer from '@/components/parts/Footer.vue'
import Jobwidget from '@/components/parts/Jobwidget.vue'

export default {
  name: "Pagecontent",
  components: {Slider, Widetext, Lilabox, Opinions, Gallery, Bubbles, Iconbar, Social, Footer, SimpleGallery, Jobwidget},
  props: ['page'],

}
</script>

<style scoped lang="scss">
.pagecontent {
  margin-top: 0px;
  position: relative;

  &.hellgruen {
    background-color: $hellgruen;
  }
}

.content {
  //max-width: 1000px;
  //padding:  20px;
  text-align: left;
  padding: 30px 5%;
  width: calc(100vw - 60px);
  background-color: $hellgruen;
}


</style>