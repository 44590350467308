<template>
  <div class="patience loading"
       style="">
    <h1 v-if="!ready">Wir erstellen Deine Einkaufsliste</h1>
    <br>
    <h1 v-if="ready">Einkaufsliste ist vorbereitet</h1>
    <h3 v-else>Bitte nicht das Browser-Fenster schließen!</h3>
    <div v-if="!ready">

      <!--      <img :src="lastProduct.images[0].src.medium" alt="" v-if="lastProduct" style="height: 100px;">-->
      <img src="@/assets/img/1491.gif" alt="" style="height: 100px;">
    </div>
    <h2 v-if="!ready && lastProduct" :class="{red:error}">
      <span v-html="lastProduct.name"></span>
      x {{ lastCart.quantity }}
    </h2>
    <a :href="getLinkFromLocation()" class="actionbutton" v-if="ready" style="align-self: center;font-weight: bold" @click="deleteBalloons" >Bestellung abschließen</a>
      <span v-if="ready" style="font-size: 0.8em;"> Konfiguration kann danach nicht mehr bearbeitet werden!</span>
    <br>
    <a class="actionbutton active" @click.prevent="backToConfig" href=""
       style="align-self: center;background-color: #aaa">Zurück zum Konfigurator</a>
    <div v-if="error" style="max-width: 500px;">
      <h3>Sorry, dieses Produkt ist nicht mehr im Lager.</h3>
      Wahrscheinlich wurde es Dir einfach vor der Nase weggeschnappt! Bitte tausche das genannte Produkt aus und starte
      den Vorgang erneut - oder kontaktiere uns, falls Du glaubst, dass hier ein Fehler vorliegt!
    </div>
  </div>
</template>

<script>
import {balloonLib, coreLib} from "@/mixins";
// import Axios from "axios";
import store from '@/store'

export default {
  name: "LoadingCart",
  mixins: [balloonLib, coreLib],
  props: ['lastCart', 'ready', 'error'],
  methods: {
    backToConfig() {

      this.$emit('returned')
    },
    getLinkFromLocation() {
      return store.state.locationlinks[this.location.ort];
    },
    deleteBalloons() {
      store.commit("saveBalloons", [])
    }
  },
  watch: {
    error() {
      store.commit('deleteProducts')
      store.dispatch("getProducts")
    }
  },
  computed: {
    lastId() {
      return this.lastCart.id
    },
    lastProduct() {
      return this.products.filter(p => p.id.toString() === this.lastId) [0]
    },
    location() {

      return store.state.location
    }

  }
}
</script>

<style scoped lang="scss">
.patience {
  background-color: rgba(#fff, 0.9);
  width: 100%;
  height: 100vh;
  top: 0;
  position: fixed;
  z-index: 300000;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h2.red {
    color: darkred
  }
}
</style>