import { render, staticRenderFns } from "./SimpleGallery.vue?vue&type=template&id=33538afc&scoped=true&"
import script from "./SimpleGallery.vue?vue&type=script&lang=js&"
export * from "./SimpleGallery.vue?vue&type=script&lang=js&"
import style0 from "./SimpleGallery.vue?vue&type=style&index=0&id=33538afc&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33538afc",
  null
  
)

export default component.exports