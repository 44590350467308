import Vue from 'vue'
import Vuex from 'vuex'
import Axios from 'axios'

Vue.use(Vuex)

// import LZString from 'lz-string'

export default new Vuex.Store({
    state: {
        base: '/wp-json/wp/v2/',
        mainmenu: [],
        footermenu: [],
        pages: [],
        locations: [],
        specialtags: [],
        location: {},
        special: { },
        balloons: [],
        products: [],
        productCats: [],
        cart: {},
        openCart:false,
        lastCartCollection: [],
        user: '',
        pass: '',
        forms: [],
        publicPath: process.env.BASE_URL,
        fixed: false
    },
    mutations: {
        openCart(state) {
          state.openCart = true
        },
        closeCart(state) {
          state.openCart = false
        },
        saveMainmenu(state, data) {
            state.mainmenu = data
        },
        saveFootermenu(state, data) {
            state.footermenu = data
        },
        savePages(state, data) {
            state.pages = data;
        },
        saveCart(state, data) {
            state.cart = data
            localStorage.setItem(
                'lastCart', JSON.stringify(data))
        },
        saveProducts(state, data) {
            state.products = data
            // state.products = data.filter(p=>p.stock_quantity>0 || !p.manage_stock)
            console.log(state.products, state.products.length)
            // localStorage.setItem(
            //     'lastProducts',
            //     LZString.compress(JSON.stringify(state.products))
            //     // JSON.stringify(state.products)
            // )
        },
        deleteProducts(state) {
            state.products = []
            localStorage.removeItem('lastProducts');
        },
        saveProductsFromCache(state, data) {
            state.products = data
        },
        saveProductCats(state, data) {
            state.productCats = data;
        },
        saveLocation(state, data) {
            state.location = data;
        },
        saveBalloons(state, data) {
            state.balloons = data;
            // console.log(state.balloons)
            localStorage.setItem(
                'balloons',
                JSON.stringify(state.balloons)
            )
        },
        setLocation(state, obj) {
            state.location = obj
            localStorage.setItem('location', JSON.stringify(obj))
        },
        saveCollection(state, obj) {
            state.lastCartCollection = obj
            localStorage.setItem(
                'lastCartCollection',
                JSON.stringify(state.lastCartCollection)
            )
        },
        getLastCollection(state) {
            let lastcart = localStorage.getItem(
                'lastCartCollection')
            state.lastCartCollection = JSON.parse(lastcart)
        },
        saveBalloon(state, balloon) {
            let index = state.balloons.map(b => b.hash).indexOf(balloon.hash)
            state.balloons[index] = balloon
            localStorage.setItem(
                'balloons',
                JSON.stringify(state.balloons)
            )
        },
        addBalloon(state, obj) {
            // state.balloons = [] //alle Ballons löschen - kann später entfernt werden
            state.balloons.push(obj)
            localStorage.setItem(
                'balloons',
                JSON.stringify(state.balloons)
            )
        },
        deleteBalloon(state, balloon) {
            // let index = state.balloons.map(b => b.hash).indexOf(balloon.hash)
            // console.log(balloon.hash)
            state.balloons = state.balloons.filter(b=>b.hash!==balloon.hash)
            localStorage.setItem(
                'balloons',
                JSON.stringify(state.balloons)
            )
        },
        toggleCart(state, balloon) {
            state.balloons.map(function(b) {
                if (b.hash===balloon.hash) {
                    if (b.cart) b.cart = false
                    else {
                        if (b.check) b.cart = true
                    }
                }
            }.bind(balloon))
            localStorage.setItem(
                'balloons',
                JSON.stringify(state.balloons)
            )
        },
        saveforms(state, obj) {
            state.forms = obj
        },
        saveanlass(state, obj) {
            state.specialtags = obj
        },
        savelocations(state,obj) {
            state.locations = obj
        },
        savelocationlinks(state,obj) {
            state.locationlinks = obj
        },
        savespecial(state,obj) {
            state.special = obj
        },
        saveFixed(state,fixed) {
            state.fixed = fixed
        }
    },
    actions: {
        getForms(store) {
            let names = ["anlass", "forms", "locations", "special","locationlinks"]
            for (let i = 0; i < names.length; i++) {

                Axios.get(store.state.publicPath + 'json/'+names[i]+'.json')
                    .then(function (response) {
                        // window.localStorage.setItem("CACHE_$" + response.url, JSON.stringify(response.data));
                        store.commit('save'+names[i], response.data);
                    });
            }

        },
        getCart(store) {
            let lastCart = localStorage.getItem('lastCart') || false
            lastCart = JSON.parse(lastCart)
            if (lastCart) {
                console.log(lastCart["cart_key"])
                Axios.get('/wp-json/cocart/v2/cart?cart_key=' + lastCart.cart_key).then(function (response) {
                    // window.localStorage.setItem("CACHE_$" + response.url, JSON.stringify(response.data));
                    store.commit('saveCart', response.data);
                });
            } else {

                Axios.get('/wp-json/cocart/v2/cart').then(function (response) {
                    // window.localStorage.setItem("CACHE_$" + response.url, JSON.stringify(response.data));
                    store.commit('saveCart', response.data);
                });
            }
        },
        getMainmenu(store) {
            Axios.get('/wp-json/navi/main').then(function (response) {
                // window.localStorage.setItem("CACHE_$" + response.url, JSON.stringify(response.data));
                store.commit('saveMainmenu', response.data);
            });

        },
        getFootermenu(store) {
            Axios.get('/wp-json/navi/Footer').then(function (response) {
                // window.localStorage.setItem("CACHE_$" + response.url, JSON.stringify(response.data));
                store.commit('saveFootermenu', response.data);
            });
        },

        getProducts(store) {
            // let lastProducts = []
            // let input = localStorage.getItem('lastProducts')
            // if (input) {
            //     lastProducts = JSON.parse(LZString.decompress(input)) || []
            // }
            // if (lastProducts.length > 0) store.commit('saveProductsFromCache', lastProducts)

            // else {
                // Axios.get('/wp-json/cocart/v2/products?per_page=1000&stock_status=instock'
                Axios.get( '/dateien/prods/products.json'
                // Axios.get(store.state.publicPath + 'dateien/zZMLXooi/products.json'
                    // Axios.get('/wp-json/cocart/v1/products?stock_status=instock'
                ).then(function (response) {
                    // window.localStorage.setItem("CACHE_$" + response.url, JSON.stringify(response.data));
                    store.commit('saveProducts', response.data);
                });
            // }
        },
        getProductCats(store) {
            Axios.get('/wp-json/cocart/v1/products/categories').then(function (response) {
                // window.localStorage.setItem("CACHE_$" + response.url, JSON.stringify(response.data));
                store.commit('saveProductCats', response.data);
            });
        },
        getPages(store) {
            Axios.get(store.state.base + 'pages?_embed&per_page=99').then(function (response) {
                // window.localStorage.setItem("CACHE_$" + response.url, JSON.stringify(response.data));
                store.commit('savePages', response.data);
            });
        },
        getLocation(store) {
            let locobj = localStorage.getItem('location')
            if (locobj) {
                let loc = JSON.parse(locobj)
                if (loc.ort !== "Dresden")  store.commit('saveLocation', loc)
            }
        },
        getBalloons(store) {
            let balobj = localStorage.getItem('balloons')
            if (balobj) {
                store.commit('saveBalloons', JSON.parse(balobj))
            }
        }
    },

    modules: {}
})
