<template>
  <div id="join-widget" ref="joinwidget" >

  </div>

</template>
<script>

export default {
  name: "Pagecontent",


  mounted() {
    if (this.$refs.joinwidget) {

      let w = this.$refs.joinwidget
      let scriptEl = document.createElement('script');
      scriptEl.setAttribute('src', "https://join.com/api/widget/bundle/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzZXR0aW5ncyI6eyJzaG93Q2F0ZWdvcnlGaWx0ZXIiOnRydWUsInNob3dMb2NhdGlvbkZpbHRlciI6dHJ1ZSwic2hvd0VtcGxveW1lbnRUeXBlRmlsdGVyIjp0cnVlLCJsYW5ndWFnZSI6ImRlIiwiam9ic1BlclBhZ2UiOjV9LCJqb2JzIjp7fSwiZGVzaWduIjp7InNob3dMb2dvIjp0cnVlLCJzaG93TG9jYXRpb24iOnRydWUsInNob3dFbXBsb3ltZW50VHlwZSI6dHJ1ZSwic2hvd0NhdGVnb3J5Ijp0cnVlLCJjb2xvcnMiOnsid2lkZ2V0Ijp7ImJhY2tncm91bmQiOiIjRkZGRkZGIiwiZmlsdGVyQm9yZGVyIjoiI0Q0RDREOCIsInBhZ2luYXRpb24iOiIjMjU2M0VCIn0sImpvYkNhcmQiOnsic2hhZG93IjoiI0Q0RDREOCIsImJhY2tncm91bmQiOiIjRkZGRkZGIiwicHJpbWFyeVRleHQiOiIjM0YzRjQ2Iiwic2Vjb25kYXJ5VGV4dCI6IiM1MjUyNUIifX19LCJ2ZXJzaW9uIjoyLCJjb21wYW55UHVibGljSWQiOiI4NTk3Nzc3ZDE3NjY5ZDg4ZTkzNzczYWUwN2I2MDMzYSIsImlhdCI6MTY4ODYzOTUwOCwianRpIjoiMjUxZThiMGUtMDYwNy00ZGU3LWE0NjAtMjQ3ZDQxNzUxYTNhIn0.zSRyJ-BjBh4pFh1yupCMP7k9BI30QDnRklnPlXrVzKQ");
      scriptEl.setAttribute('type', 'text/javascript');
      scriptEl.setAttribute('data-mount-in', "#join-widget");
      console.log(this.$refs.joinwidget)
      w.appendChild(scriptEl);
    }
  }
}
</script>