<template>
  <div class="lilabox">
    <div class="kopf" v-if="block.kopfzeile" :class="{acc:block.akkordeon}" @click="toggleAcc">
      <span v-html="block.kopfzeile"></span>
      <div class="triangle" :class="{open:open}" v-if="block.akkordeon"></div>
    </div>

    <div class="content" :class="{closed: !open && block.akkordeon}">
      <div class="inner">

        <h2 v-html="block.uberschrift" v-if="block.uberschrift"></h2>
        <div style="display: flex;width: 100%;flex-wrap: wrap">

          <div v-html="block.textfeld" :class="{wide:!block.textfeld2}" class="textfeld"></div>
          <div v-html="block.textfeld2" :class="{}" class="textfeld" v-if="block.textfeld2"></div>
        </div>

      </div>

    </div>
    <div class="foot" v-if="block.fusszeile">
      {{ block.fusszeile }}
    </div>
      <Social v-if="block.social_buttons" :social="block.social"></Social>


  </div>
</template>
<script async src="https://www.tiktok.com/embed.js"></script>
<script>
import Social from '@/components/parts/Social.vue'

export default {
  name: "Lilabox",
  components: {Social},
  data() {
    return {
      open: false
    }
  },
  props: ['block'],
  methods: {
    toggleAcc() {
      if (this.block.akkordeon) this.open = !this.open;
    }
  }
}
</script>

<style scoped lang="scss">
.lilabox {
  margin: 20px 5%;
  @include mobile {
    margin: 10px 5%;
  }
  text-align: left;
  border: 1px solid $lila;
  position: relative;

  .content {
    &.closed {
      height: 0;
      overflow: hidden;
    }

    background-color: #fff;

    .inner {

      padding: 10px 30px;
      @include mobile {
        padding: 10px 15px;
      }
    }

    .textfeld {
      width: 50%;
      padding: 0 20px;

      &.wide {
        width: 80%;
      }
      @include mobile {
        padding: 0;
        width: 100%;
        &.wide {
          width: 100%;
        }
      }
    }

    h2 {
      color: $lila;
      text-transform: uppercase;
    }

  }

  .kopf, .foot {
    background-color: $lila;
    padding: 30px;
    color: #fff;
    font-size: 24px;
    @include mobile {
      font-size: 1em;
      padding: 10px 15px;
    }
    margin: 0;
    font-weight: 600;
  }

  .kopf {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.acc {
      cursor: pointer;
    }

    .triangle {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 20px 10px 0 10px;
      border-color: #fff transparent transparent transparent;
      transition: transform 0.2s;

      &.open {
        transform: rotateX(180deg);
      }
    }
  }


}
</style>