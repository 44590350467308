<template>
  <div>

    <div class="cartswitch" @click="openCart">
      <div class="hook">

        <img src="@/assets/img/symbols/cart.svg" alt="">
      </div>

      <div class="text" style="">Meine <br>Wunschballons</div>
    </div>
    <div style="" class="cart" :class="{hidden:!cartOpen}">
      <div style="" class="inner">

        <div>
          <h1 style="color:#fff;display: flex;align-items: flex-start;justify-content:space-between;line-height: 32px;">
            <span>Meine <br> Wunschballons</span> &nbsp;
            <div @click="closeCart" style="cursor: pointer;"><img src="@/assets/img/symbols/back.svg" alt=""></div>
          </h1>

          <br>
          <br>
          <div style="display: flex;align-items: center">
            <img src="@/assets/img/symbols/cart.svg" alt=""> &nbsp;&nbsp;
            <div>Warenkorb</div>
          </div>
          <hr style="margin: 10px 0;">
          <div class="list">
            <Setlist v-for="(b,index) in cartBalloons" :b="b" :key="'b'+index" class="line" :hash="hash"
                     @closeCart="closeCart"></Setlist>

          </div>
          <div style="display: flex;justify-content: flex-end;padding-top: 10px;">


            <a style="" class="actionbutton" v-if="collection.length>0"
               @click="fillCart()"><img src="@/assets/img/symbols/weiter.svg" alt="">
              &nbsp;Zur Kasse
            </a>
            <div v-else>Fertige Konfigurationen hinzufügen</div>
            <!--            {{collection}}-->

          </div>
        </div>
        <br>
        <br>
        <div>

          <div> Angefangene Konfigurationen</div>
          <hr style="margin: 10px 0;">
          <div class="list">
            <Setlist v-for="(b,index) in incompleteBalloons" :b="b" :key="'incomplete'+index" class="line"></Setlist>

          </div>
          <div style="display: flex;justify-content: flex-end;padding-top: 10px;" >

            <router-link to="/konfigurator" class="actionbutton"

                         @click="fillCart()"><img src="@/assets/img/symbols/weiter.svg" alt="">
              &nbsp;Neue Konfiguration
            </router-link>
          </div>
        </div>
      </div>

    </div>
    <LoadingCart v-if="cartLoading" :lastCart="lastCart" :ready="ready" @returned="returnFromCheckout"
                 :error="sendingError"></LoadingCart>
  </div>

</template>

<script>
import store from '@/store'
import {balloonLib, coreLib} from "@/mixins";
import Axios from "axios";
import Setlist from '@/components/balloons/forms/parts/Setlist.vue';
import LoadingCart from '@/components/parts/LoadingCart.vue'

export default {
  name: "Cart",
  data() {
    return {
      allBalloons: [],
      cartBalloons: [],
      incompleteBalloons: [],
      hidden: true,
      cartLoading: false,
      ready: false,
      lastCart: {},
      sendingError: false,
      isFixed: false
    }
  },
  props: ['from'],
  components: {Setlist, LoadingCart},
  watch: {
    balloons: {
      handler: function (val) {
        this.allBalloons = val
        this.cartBalloons = val.filter(b => b.check && b.cart).map(function (b) {
          if (!b.stueck) b.stueck = 1
          return b
        })
        this.incompleteBalloons = val.filter(b => !b.check || !b.cart)
      },
      deep: true
    },
    cartOpen() {
      if (this.from === 'home') {
        if (this.products.length === 0) store.dispatch('getProducts')
      }
    },

  },
  computed: {
    collection() {
      let coll = this.cartBalloons.filter(b => b.check).map(function (b) {
        return b.collection
      })
      return coll.flat(1)
    },
    cartOpen() {
      return store.state.openCart
    }
  },
  methods: {
    returnFromCheckout() {
      this.cartLoading = false
      this.ready = false
      this.sendingError = false
      Axios.post('/wp-json/cocart/v2/cart/clear')
      store.commit('saveCart', {})
    },
    changeStueck(b, event) {
      b.stueck = event.target.value
      console.log(b, event)
    },
    openCart() {
      store.commit('openCart')
    },
    closeCart() {
      store.commit('closeCart')
    },
    writeCollection() {
      this.collect = store.state.balloons
    },
    fillCart() {
      console.log(this.collection)
      if (this.collection.length>1) {

        this.cartLoading = true
        Axios.post('/wp-json/cocart/v2/cart/clear').then(function () {
          store.commit('saveCart', {})
          this.lastCart = {}
          this.addCollectionToCart()
        }.bind(this))
      }
    },
    addCollectionToCart() {
      let index = 0;

      function send(c, obj) {
        let data = c[index]
        obj.lastCart = data
        console.log(c)
        Axios.post('/wp-json/cocart/v2/cart/add-item', data
        )
            // .catch(function (error) {
            // }).
            .then(function (response) {
              console.log(response)
              index++;
              if (index === c.length) {
                store.commit('saveCart', response.data)
                store.commit('saveCollection', c)
                obj.ready = true
                // obj.cartLoading = false
              } else {
                if (obj.cartLoading) return send(c, obj);
              }
            })
            .catch((error) => {
              console.log(error);
              obj.sendingError = true
            });
      }

      return send(this.collection, this);
    },

  },
  mixins: [balloonLib, coreLib],
}
</script>

<style scoped lang="scss">

.cart {
  .inner {
    height: 100vh;overflow: auto;padding: 50px;
    @include phone {
      padding: 20px;
    }
  }
  &.hidden {
    transform: translateX(-100%)
  }

  @include phone {
    max-width: 100vw;
    max-height: 100vh;
    overflow: auto;
  }
  transition: transform 0.2s ease;
  color: #fff;
  position: fixed;
  left: 0;
  top: 0;
  width: 530px;
  height: 100vh;
  overflow-x: visible;
  background-color: $lila;
  z-index: 90000;
  text-align: left;

  .list {

  }
}

.cartswitch {
  .hook {
    background-color: $lila;
    height: 64px;
    width: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 30px 30px 0;
  }

  @include phone {
    top: 10px;
    transform: scale(0.7);
    transform-origin: top left;
  }

  //width: 50px;
  position: fixed;
  display: flex;

  &:hover {

    background-color: rgba(#fff, 0.9);
  }

  &:hover {

    //background-color: lighten($lila, 10%);
    //color: #fff;
    .text {
      display: flex;
    }
  }

  height: 64px;
  align-items: center;
  left: 0;
  top: 50px;
  color: $lila;
  //background-color: $lila;
  z-index: 80000;
  cursor: pointer;

  .text {
    text-align: left;
    margin-left: 10px;
    padding: 10px;
    //height: 54px;
    font-size: 20px;
    //line-height: 32px;
    text-transform: uppercase;
    font-weight: 700;
    display: none;

    &.visible {
      display: flex;
      @include mobile {
        display: none;
      }
    }


  }
}

</style>