import { render, staticRenderFns } from "./Setlist.vue?vue&type=template&id=993fb8b6&scoped=true&"
import script from "./Setlist.vue?vue&type=script&lang=js&"
export * from "./Setlist.vue?vue&type=script&lang=js&"
import style0 from "./Setlist.vue?vue&type=style&index=0&id=993fb8b6&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "993fb8b6",
  null
  
)

export default component.exports