<template>
  <div class="gallery">
    <div v-for="b in gallery.block" class="block">
      <div v-if="b.type" class="fullpic">
        <div class="inner" style="padding-bottom: 0;" v-if="b.kachel.typ">

          <img :src="b.kachel.bild.sizes.large" alt=""
               style="aspect-ratio: 800/594; padding-bottom: 0;">
        </div>
        <div class="inner" v-else>

          <video style="height: 100%;width: 100%;" controls>
            <source :src="b.kachel.video.url" type="video/mp4"/>
          </video>
        </div>
        <!--    {{b.type}}-->
        <!--    {{b.kachel}}-->
      </div>
      <div v-else>

        <div class="grid">

          <div v-for="bild in b.bilder" :key="bild.bild.id" class="pic" v-if="b.bilder.length === 4">
            <div class="inner">
              <div class="overlay" :style="{backgroundColor: hexToRGB(bild.overlay.color, 0.9)}"
                   v-if="bild.overlay.color">
                <div>{{ bild.overlay.text }}</div>
              </div>

              <img :src="bild.bild.sizes.medium_large" alt="" v-if="bild.bild">
              <img src="@/assets/img/placeholder.png" alt="" v-else>

            </div>
          </div>
          <div :key="b.bilder[0].bild.id" class="pic" v-if="b.bilder.length === 3">
            <div class="inner high">

              <img :src="b.bilder[0].bild.sizes.medium_large" alt="" v-if="b.bilder[0].bild"/>
            </div>
          </div>
          <div class="pic" v-if="b.bilder.length === 3">
            <div v-for="(bild,index) in b.bilder" class="inner" v-if="index>0">
              <div class="overlay" :style="{backgroundColor: hexToRGB(bild.overlay.color, 0.9)}"
                   v-if="bild.overlay.color">
                <div>{{ bild.overlay.text }}</div>
              </div>
              <img :src="bild.bild.sizes.medium_large" alt="" v-if="bild.bild">
              <img src="@/assets/img/placeholder.png" alt="" v-else>
            </div>
          </div>

          <!--      {{b.bilder}}-->
          <!--    {{b.type}}-->
        </div>
      </div>
    </div>

    <!--  {{block}}-->
  </div>
</template>

<script>
export default {
  name: "Gallery",
  props: ['gallery'],
  methods: {
    hexToRGB(hex, alpha) {
      var r = parseInt(hex.slice(1, 3), 16),
          g = parseInt(hex.slice(3, 5), 16),
          b = parseInt(hex.slice(5, 7), 16);

      if (alpha) {
        return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
      } else {
        return "rgb(" + r + ", " + g + ", " + b + ")";
      }
    }

  }
}
</script>

<style scoped lang="scss">
.gallery {
  padding-left: 1%;
  padding-right: 1%;
  margin: 30px;

  .block {
    min-width: 50%;
    max-width: 50%;
@include mobile {
  max-width: unset;
}
    .fullpic {
      .inner {
        padding: 0.5%;

        img {
          width: 100%;
          vertical-align: bottom;
          display: block;

        }
      }

    }
  }

  .grid {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    .pic {
      width: 50%;
      min-width: 50%;

      .inner {
        position: relative;

        img {
          aspect-ratio: 400/296;
        }

        &.high {
          img {
            aspect-ratio: 4/6;
          }
        }

        //margin-left: 1%;
        padding: 1%;
        //margin-top: 0.5%;
        .overlay {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 98%;
          height: calc(97% + 1px);
          //background-color: rgba(blue,0.8);

          color: #fff;
          font-size: 36px;
          @include mobile {
            font-size: 24px;
          }
          font-weight: 700;
          text-transform: uppercase;
          opacity: 1;
          transition: opacity 0.2s ease;

          &:hover {
            opacity: 0;
          }
        }
      }

      img {
        width: 100%;
        display: block;

      }
    }
  }

  display: flex;
  flex-wrap: wrap;
}
</style>