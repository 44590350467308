// import crypto from "crypto";
// const createHash = require('hash-generator');
// import createHash from "hash-generator"
import store from '@/store'
import Axios from "axios";

export let coreLib = {
    data() {
        return {
            balloonset: {baseproduct: []},
        }
    },
    created() {
        store.dispatch('getBalloons')
        store.commit('getLastCollection')

    },
    watch: {
        baseproduct() {
            if (this.balloonset) this.balloonset.baseproduct ? this.balloonset.baseproduct[0] = this.baseproduct : {}
        },
        balloons() {
            this.balloonset = this.balloons.filter(b => b.hash === this.$route.params.hash)[0]
        },
        hash() {
            this.balloonset = this.balloons.filter(b => b.hash === this.$route.params.hash)[0]
        }
    },
    computed: {
        compareCollections() {
            //vergleicht letztes Warenkorbobjekt mit dem aktuellen Bouquetobjekt
            return JSON.stringify(this.lastCartCollection) === JSON.stringify(this.collection)
        },

    },
    methods: {
        clearCart() {
            Axios.post('/wp-json/cocart/v2/cart/clear').then(function (response) {
                console.log(response)
                store.commit('saveCart', {})
            })
        },
        switchOption(opt, val) {
            this.balloonset[opt] = val;
            this.updateData();
        },
        updateBalloonset(b) {
            // console.log('updated', b)
            this.$set(this.balloonset[b.typ], b.index, b.value)
            this.updateData();
        },
        updateOption(b) {
            console.log(b)
            this.balloonset[b.option] = b.value
            this.updateData();
        },
        removeBalloon(b) {
            this.balloonset[b.typ].splice(b.index, 1)
            this.updateData();
        },
        addEmptyProduct(typ) {
            this.balloonset[typ].push({})
            this.updateData();
        },

        updateData() {
            if (this.collection) this.balloonset.collection = this.collection
            this.balloonset.check = this.checkCollection
            // console.log(this.balloonset.check)
            store.commit('saveBalloon', this.balloonset)
        },
        sendItemToCart(data) {
            Axios.post('/wp-json/cocart/v2/cart/add-item', data
            ).then(function (response) {
                // console.log(response)
                store.commit('saveCart', response.data)
            }.bind(this));
        },

        addMoney(value) {
            let fiveEuro = this.getProductBySku(this.special.money)
            console.log(this.special.money)
            console.log(this.getProductBySku(this.special.money))
            let quantity = Math.floor(value / 5)
            return {
                id: fiveEuro.id.toString(),
                quantity: quantity.toString(),
                item_data: {
                    stock_location: this.location.id,
                },
                return_item: true
            }
        },
        addMoneySurcharge(value) {
            let surcharge = this.getProductBySku(this.special.moneysurcharge)
            console.log(this.special.moneysurcharge)
            console.log(this.getProductBySku(this.special.moneysurcharge))
            let quantity = Math.floor(value / 5)
            return {
                id: surcharge.id.toString(),
                quantity: quantity.toString(),
                item_data: {
                    stock_location: this.location.id,
                },
                return_item: true
            }
        },
        addBag() {
            let bag = this.getProductBySku(this.special.bag)
            // console.log("Bag:"+bag)
            // console.log(this.special.bag)
            return {
                id: bag.id.toString(),
                item_data: {
                    stock_location: this.location.id,
                },
                return_item: true
            }
        }
    }
}

export let balloonLib = {
    data() {
        return {
            // forms: forms,
            labelcat: "",
            fotocat: ""
        }
    },
    created() {
        store.dispatch('getLocation')
        if (this.balloons.length === 0) store.dispatch('getBalloons')
    },
    watch: {
        special() {
            this.labelcat = this.special.labelcat
            this.fotocat = this.special.fotocat
        },

    },
    updated() {
    },
    computed: {
        baseproduct() {
            return this.form && this.special[this.form.id] ? this.getProductBySku(this.special[this.form.id]) : {}
        },
        location: () => store.state.location,
        locations: () => store.state.locations,
        balloons: () => store.state.balloons,
        forms: () => store.state.forms,
        special: () => store.state.special,
        products: () => store.state.products,
        lastCartCollection: () => store.state.lastCartCollection,
        hash() {
            return this.$route.params.hash || false
        },
        cart() {
            return store.state.cart
        },
        balloon() {
            return store.state.balloons.filter(b => b.hash === this.hash)[0]
        },
        form() {
            return this.hash ? this.forms.filter(f => f.id == this.balloon.form)[0] : false
        },
        allCollections() {
            let coll = store.state.balloons.map(function (b) {
                return b.collection?b.collection:[]
            })

            return coll.flat(1)
        }
    },
    methods: {
        isLatexColor(p) {
            // console.log(p.tags)
            return p.tags ? p.tags.filter(t => t.name = "LBF").length > 0 : false
        },
        getStock(loc, p) {
            let stock = 0;
            // p.locations.forEach(function (l) {
            //     // console.log(parseInt(loc.id) == parseInt(l.id))
            //     if (parseInt(loc.id) == parseInt(l.id)) stock = l.quantity
            //
            // }.bind(stock))
            let locid = this.locations.filter(l=>parseInt(loc.id) === parseInt(l.id)).map(l=>l.id)[0]
// stock =
            let meta = p.meta_data.filter(m=>m.key==="_stock_at_"+locid)
            // console.log
            if (meta.length>0) stock = meta[0].value
            else stock = p.stock.stock_quantity
            // else console.log(p.stock.stock_quantity)
            // console.log(meta)
            // console.log(this.allCollections)
            // console.log(p)
            if (this.allCollections) {

            let chosen = this.allCollections.filter(item => item.id == p.id)
            let chosenCount = chosen.reduce((a, b) => a + parseInt(b.quantity), 0)
            return stock - chosenCount;
            }
            else return stock;
            // console.log(stock - chosenCount)
        },

        getProductBySku(sku) {
            return sku ? store.state.products.filter(p => p.sku.toString() === sku.toString())[0] : false
        },
        scrollTo(id) {
            let elmnt = document.getElementById(id);
            elmnt.scrollIntoView(({behavior: "smooth", block: "start"}));
        },
        eurofy(price) {
            return (price / 100).toFixed(2).replace(".", ",") + ' €'
        },
        numberfyPrice(str) {
            return parseInt(str.replace(/[^0-9.-]+/g, ""))
        },
        computePrices(arr) {
            let prices = []
            if (arr.length === 0) return 0
            arr.forEach(function (a) {
                if (a.productprices) prices = [...prices, ...a.productprices]
            })
            return prices.reduce(function (sum, p) {
                return sum + this.numberfyPrice(p)
            }.bind(this), 0)
        },
        sumPrices(arr) {
            let sum = arr.reduce(function (acc, val) {
                return acc + val;
            }, 0)
            return sum
        },
        checkCat(p, cat) {
            let cats = p.categories ? JSON.parse(JSON.stringify(p.categories)) : []
            let filteredCats = cats.filter(c => c.name === cat)
            return filteredCats.length > 0
        },
        orderById(c) {
            return c.reduce((acc, obj) => {
                    const id = obj ? obj.id : false
                    if (id) {
                        acc[id] = acc[id] || [];
                        acc[id].push(obj);
                    }
                    return acc
                }, {}
            )
        }
    }

}

export let scrollHandler = {
    data: function () {
        return {
            scrollPosition: 0,
            docHeight: 0,
            vpHeight: window.innerHeight,
        }
    },
    components: {},
    computed: {},
    methods: {
        handleScroll: function () {
            // jQuery('.slidecontent').css('transform', this.slideroffset(-0.2, window.pageYOffset))
            window.requestAnimationFrame(function () {
                window.pageYOffset >= 0 ? this.scrollPosition = window.pageYOffset : 0;
                if (this.$refs.home) {

                    this.docHeight = this.$refs.home.clientHeight
                    this.vpHeight = window.innerHeight

                }
            }
                .bind(this))
        },
        scrollToTop() {
            $('html,body').animate({scrollTop: 0}, 500);
        },
        shortLink: function (link) {
            return link.replace(/^.*\/\/[^\/]+/, '');
        },
    },

    destroyed() {
        window.removeEventListener('DOMContentLoaded', this.handleScroll);
    },
    mounted: function () {
        window.addEventListener('scroll', this.handleScroll);
    },
    watch: {}
};

export let resizeHandler = {
    data: function () {
        return {
            scrHeight: 0,
            scrWidth: 0
        }
    },
    methods: {
        getSize: function () {
            this.scrHeight = window.innerHeight;
            this.scrWidth = window.innerWidth
            // console.log(this.scrHeight)
        },

    },
    destroyed() {
        window.removeEventListener('resize', this.getSize);
    },
    created: function () {
        this.getSize();
        window.addEventListener('resize', this.getSize)
    },

};

export let connectionCheck = {

    data() {
        return {
            offline: false
        }
    },
    created() {
        window.addEventListener('online', this.networkChange);
        window.addEventListener('offline', this.networkChange)
    },
    methods: {
        networkChange(e) {
            this.offline = e.type == "offline";
        }
    }
};