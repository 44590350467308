<template>
  <div class="widetext" :class="[block.color, {cloud:block.wolke}]">
    <h1 v-html="block.header"></h1>
    <div class="wrap">

      <div class="widecontent" v-html="block.inhalt"></div>
      <div class="buttonbox " v-if="block.buttontext || block.buttonlink">
        <router-link :to="shortLink(block.buttonlink)" class="button">
          {{ block.buttontext }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Widetext",
  props: ['block']

}
</script>

<style scoped lang="scss">
.wrap {
  display: flex;
  justify-content: stretch;
  flex-wrap: wrap;
}
.widetext {
  text-align: left;
  color: #333;
  padding: 30px 5%;
  h1 {
    color: $lila;
  }
  .widecontent {
    max-width: 60%;
    @include mobile {
      max-width: 100%;
    }
  }

  &.weiss {

  }

  &.hellblau {


    background-color: $hellblau;
  }

  &.lila {
    background-color: $lila;
    color: #fff;
    h1 {
      color: #fff;
    }
  }
  &.cloud {
    background-image: url("../../assets/img/PF_Website-Wolke Banner_Weiss.png");
    background-size:20%;
    background-repeat: no-repeat;
    background-position: right 0 bottom -2px;
  }
}

.widecontent {

}

.buttonbox {
  width: 40%;
  @include mobile {
    width: 100%;
  }
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 20px;
}
</style>