import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false
import {scrollHandler,connectionCheck, balloonLib} from '@/mixins'
Vue.mixin(scrollHandler);
Vue.mixin(connectionCheck);
// Vue.mixin(balloonLib);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
