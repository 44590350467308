<template>
  <div :class={active:activeLine()}>

    <div style="display: flex;align-items: center">

      <div style="width: 20px;height: 20px;display: flex;align-items: center" >
        <img src="@/assets/img/symbols/completed.svg" alt="" style="width: 14px;" v-if="b.check">
      </div>
      <a  style="color:#fff;" class="name" @click="goToBouquet(b)">
        {{ b.name }}
      </a>
    </div>
    <div v-for="(c,index) in b.collection" :key="index" v-if="c.item_data.info">
      <div>
        <!--            {{ c.quantity }} x {{ c.id }} - -->
        <span v-if="c.item_data.info.price">{{ eurofy(c.item_data.info.price.full * (b.stueck ? b.stueck : 1)) }}</span>
      </div>
    </div>
    <div style="width: 20%;display: flex;justify-content: space-between;align-self: flex-end">
      <div @click="toggleCart(b)" :class="{active:b.cart}" class="carttrigger" style="width: 33%;cursor:pointer">
        <img src="@/assets/img/symbols/cart.svg" alt="" style="width: 14px;" v-if="b.check">
      </div>
      <div style="width: 33%;">
        <a :to="{ name: 'Editor', params: { hash: b.hash }}" v-if="b.hash!==hash" @click="goToBouquet(b)">
          <img src="@/assets/img/symbols/edit.svg" alt="">
        </a>
      </div>
      <div style="width: 33%;">
        <div >

          <a @click="deleteBalloonset(b)" v-if="b.hash!==hash"> <img src="@/assets/img/symbols/trash.svg" alt=""></a>
          <a v-else @click="deleteBalloonset(b,true)"> <img src="@/assets/img/symbols/trash.svg" alt=""></a>
        </div>
      </div>
    </div>

  </div>


</template>
<script>
import store from '@/store'
import {balloonLib, coreLib} from "@/mixins";

export default {
  name: "Setlist",
  props: ['b'],
  mixins: [balloonLib, coreLib],
  methods: {
    deleteBalloonset(b, move) {
      store.commit('deleteBalloon', b)
      if (move) this.$router.push('/konfigurator')
    },
    activeLine() {
      return this.hash === this.b.hash
    },
    toggleCart(b) {
      if (b.check) store.commit('toggleCart', b)
    },
    goToBouquet(b) {
      this.$router.push({ name: 'Editor', params: { hash: b.hash }})
      this.$emit('closeCart')
    }
  }
}
</script>

<style scoped lang="scss">
.line {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #91728C;

  a {
    cursor: pointer;

    &:hover {
      img {

        transform: scale(1.1);
      }
    }
  }
}

.carttrigger {
  opacity: 0.6;

  &.active {
    opacity: 1;
  }
}

.active {
  font-weight: bold;

  .name {
  }
}
</style>