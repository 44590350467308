<template>
  <div class="iconbar" :class="{cloud:block.wolke}">
    <div v-for="i in block.icons" class="iconblock">
      <div class="icon"><img :src="i.icon.url" alt=""></div>
      <div class="big">{{ i.bigtext }}</div>
      <div class="small">{{ i.smalltext }}</div>


    </div>
  </div>
</template>

<script>
export default {
  name: "Iconbar",
  props: ['block']
}
</script>

<style scoped lang="scss">
.iconbar {
  &.cloud {
    background-image: url("../../assets/img/PF_Website-Wolke Banner_Weiss.png");
    background-size:20%;
    background-repeat: no-repeat;
    background-position: right 0 bottom -2px;
  }
  background-color: $lila;
  display: flex;
  justify-content: space-between;
  width: 75%;
  @include mobile {
    width: 100%;
    padding: 10px 0;
  }
  //padding: 20px;
  padding: 40px 20% 40px 6%;

  .iconblock {
    .icon {
      text-align: center;

      img {
        width: 100px;
        @include mobile {
          width: 80%;
          padding: 0 10%;
        }
      }
    }

    display: flex;
    flex-direction: column;
    color: #fff;

    .big {
      font-size: 36px;
      @include mobile {
      font-size: 30px;

      }
      font-weight: 600;
      min-height: 52px;
    }

    .small {
      font-size: 22px;
      @include mobile {
        font-size: 12px;
      }
    }
  }
}
</style>