<template>
  <div class="social">

    <div class="circle" v-if="social.insta">
      <a target="_blank" :href="'https://www.instagram.com/'+social.insta" title="Instagram">

        <img src="@/assets/img/Instagram_Logo_RGB_72dpi.png" alt="">
      </a>
    </div>
    <div class="circle" v-if="social.facebook">
      <a target="_blank" :href="'https://www.facebook.com/'+social.facebook" title="Facebook">

        <img src="@/assets/img/facebook_Logo_RGB_72dpi.png" alt="">
      </a>
    </div>
    <div class="circle" v-if="social.whats_app">
      <a target="_blank" :href="'https://web.whatsapp.com/send?phone='+social.whats_app" title="What's App">

        <img src="@/assets/img/WhatsApp_Logo_RGB_72dpi.png" alt="">
      </a>
    </div>
    <div class="circle" v-if="social.tiktok">
      <a target="_blank" :href="'https://www.tiktok.com/'+social.tiktok" title="Tiktok">

        <img src="@/assets/img/tiktok.png" alt="">
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Social",
  props: ['social'  ]
}
</script>

<style scoped lang="scss">

.social {
  //display: flex;
  //padding: 0 30px;
    position: absolute;
  right: 0;
    top: 50px;

  .circle {
    //padding: 20px;
  }
}
.social {
  //position: absolute;
  //top: 15vh;

  right: 0;

  img {
    width: 2vw;
min-width: 15px;
  }

  .circle {
    cursor: pointer;

    a {
      border-radius: 50% 0 0 50%;
      padding: 15px;

      @include mobile {
        padding: 10px;

      }
      display: block;
      opacity: 1;
      margin-bottom: 5px;
      transition: 0.5s transform ease;
      background-color: rgba(#eee,0.5);

      img {
        display: block;
      }

      &:hover {
        opacity: 1;
        //transform: scale(1.2);
        //padding-right: 30px;
        img {
          transition: 0.2s transform ease;
        transform: scale(1.2);
        }
        //padding-right: 30px;





      }
    }

  }
}

</style>