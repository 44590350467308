<template>
  <div class="slider">
    <div class="slide" v-for="(slide,index) in slides" v-if="index===position" :style="{aspectRatio: slide.bild.width / slide.bild.height, backgroundImage: 'url(' + slide.bild.url + ')'}">

<!--      <img :src="slide.bild.url" alt="">-->
      <div class="slidecontent">
        <div v-html="slide.text" class="text"></div>
        <router-link :to="shortLink(slide.buttonlink)" class="button" v-if="slide.buttontext || slide.buttonlink">
          {{ slide.buttontext }}
        </router-link>
      </div>
    </div>

    <div class="slidemenu" v-if="slides.length>1">
      <div class="point" v-for="(s,index) in slides" @click="position=index" :class="{active:position===index}">

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Slider",
  data() {
    return {
      position: 0
    }
  },

  watch: {
    $route: {
      handler: function() {
      this.position = 0
      },
      deep: true,
    }
  },
  props: ['slides']
}
</script>

<style scoped lang="scss">
.slider {
  position: relative;
//background-color: $lila;
  .slide {
    position: relative;
    top: 0;
    min-height: 50vh;
    @include phone {
      min-height: unset;
    }
    background-size: cover;
    //margin-top: -5px;
    width: 100%;
max-width: 100%;
    &.invisible {
      position: relative;
      visibility: hidden;
    }
    img {
      max-width: 100%;
      width: 100%;
      height: 100%;
      display: block;
    }
  }
  .slidecontent {
    position: absolute;
    top: 20%;
    left: 5%;
    width: 20%;
    text-align: left;
    @include phone {
      font-size: 14px;
      top: 10%;
    }
    .text {
      text-transform: uppercase;
      font-weight: 700;
      color: #fff;
      font-size: 40px;
      text-align: left;
      @include phone {
        font-size: 14px;

      }
    }
    @include phone {
      a.button {
        font-size: 12px;
      }
    }
  }
  .slidemenu {
    position: absolute;
    bottom: 10px;
    display: flex;
    width: 100%;
    justify-content: center;
    .point {
      margin: 10px;
      transition: background-color 0.3s ease;
      cursor: pointer;
      &.active {
        cursor: default;
        background-color: #fff;
      }
      background-color: #ccc;
      width: 20px;
      border-radius: 50%;
      height: 20px;
      @include mobile {
        width: 12px;
        height: 12px;
        margin: 0 10px;
      }
    }
  }
}
</style>