<template>
  <div class="home">

    <Menu></Menu>

    <div class="placeholder" v-if="!page">

      <img src="@/assets/img/1491.gif" alt="">
    </div>

    <Pagecontent v-if="page" :page="page"></Pagecontent>
    <Cart from="home"></Cart>
    <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import store from '@/store'
import Menu from '@/components/Menu.vue'
import Pagecontent from '@/components/Pagecontent.vue'
import Cart from '@/components/balloons/Cart.vue'
import Footer from '@/components/parts/Footer.vue'


export default {
  name: 'Home',
  created() {
    // store.dispatch('getMainmenu')
    if (store.state.pages.length < 1) store.dispatch('getPages')
  },
  watch: {
    // $route: {
    //   handler: function () {
    //     this.scrollToTop()
    //   },
    //   deep: true,
    // },
  },
  components: {
    Menu, Pagecontent, Cart, Footer
  },
  computed: {
    pages: () => store.state.pages,
    page() {
      let page = store.state.pages.filter(function (p) {
        return p.slug === this.$route.params.slug
      }.bind(this))
      return page[0]
    }
  }
}
</script>

<style lang="scss">
.placeholder {
  height: 100vh;
  width: 100%;
  background-color: #fff;
}
</style>
